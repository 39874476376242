#carmon-wrapper {
    min-height: 100vh;
    position: relative;
    padding-bottom: 152px; /* footer height + 20px */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

/*.login-slick .slick-track {*/
/*    display: flex !important;*/
/*}*/

/*.login-slick .slick-slide {*/
/*    height: auto;*/
/*}*/

.login-slick .slick-slide > div {
    height: 100%;
    border: 1px solid crimson;
}

/*.login-slick .slick-slide .login-slick-inner {*/
/*    flex: 1;*/
/*    border: 1px solid blue;*/
/*}*/

.ads {
    font-family: 'Pretendard-Black', serif !important;
}

.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
    box-shadow: 0 0 !important;
}

.carmon-navbar-menu {
    height: 90px;
    margin-bottom: 70px;
}

.carmon-view-container {
    width: 100%;
    flex: 1;
    display: flex !important;
    justify-content: center;
    background-color: #f0f0f0;
    padding-top: 102px; /* header height + 20px */
    padding-left: 12px;
    padding-right: 12px;
}

.carmon-footer {
    position: absolute !important;
    left: 0;
    right: 0;
    bottom: 0;
    height: 132px;
    border: 0 !important;
    padding: 12px 16px;
    background-color: #1B1C1D;
    color: #f0f0f0;
    vertical-align: middle;
}

.sf-check-box-list-item {
    padding: 4px 0 !important;
    display: flex !important;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.sf-check-box-list-item > i.icon {
    margin: 0 !important;
    padding: 0 !important;
}

.sf-check-box-list-item > .content {
    margin-top: 4px;
}

.bid-list-search-conditions {
    max-width: 320px;
    min-width: 240px;
    margin-right: 16px;
}

.bid-list, .my-bid-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    min-width: 540px;
    width: 100%;
    /*max-width: 1916px;*/
    max-width: 1348px;
}

.bid-list-tags {
    padding-top: 3px;
    padding-bottom: 3px;
}

.bid-list-tags > a.ui.label:first-child {
    margin-left: 0.14285714em;
}

.bid-list-tags > a.ui.label {
    background-color: #00aed9;
    color: #ffffff;
}

.bid-list-items {
    width: 320px !important;
    height: 400px !important;
    margin: 0 !important;
}

.bid-item-car-info {
    max-width: 320px;
    min-width: 260px;
    margin-left: 16px;
}

.bid-item-main-info {
    flex: 1;
    margin-right: 16px;
    min-width: 540px;
    width: 100%;
    max-width: 1148px;
}

.bid-item-slider {
    background-color: #696969 !important;
    padding: 8px 30px 2px 30px !important;
    /*max-width: 1200px !important;*/
    /*max-height: 800px !important;*/
}

.bid-item-view-count {
    padding: 0 !important;
}

.criteria-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.criteria-item > div.criteria-title {
    flex: 1;
    padding: 4px !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
}

.criteria-item > div.criteria-title:hover {
    background-color: #feb9480f;
    cursor: pointer;
}

.criteria-item > div.criteria-title > span:first-child {
    font-size: 16px;
}

.criteria-item > div.criteria-title > span:last-child {
    font-size: 12px;
    color: #696969;
}

.criteria-item > div.action-button {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slide-image {
    padding: 0 !important;
    border-radius: 0 !important;
    border-width: 0 !important;
    height: 100vh;
    max-height: 480px;
}

.slide-image > .ui.label.point-label {
    opacity: 0.9;
    background-color: #feb948;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold !important;
    border-top-left-radius: 0 !important;
}

.slide-image > .ui.label.mag-label {
    background-color: transparent;
    padding: 0;
}

.slide-image > .ui.label.mag-label > button {
    padding: 10px 6px 10px 10px;
    margin: 2px 2px 0 0;
}

.slide-image > .ui.label.mag-label > button > i {
    padding: 0 !important;
    margin: 0 !important;
}

.slide-thumbnail {
    flex: 0 0 calc(12.5% - 1px);
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
    border-width: 0 !important;
}

table.performance-check-record td.title {
    background-color: rgba(105, 105, 105, 0.1);
}

table.performance-check-record th.title {
    font-weight: bold;
    background-color: rgba(105, 105, 105, 0.1);
}

table.accident-history td.title {
    background-color: rgba(105, 105, 105, 0.1);
}

table.accident-history th.title {
    font-weight: bold;
    background-color: rgba(105, 105, 105, 0.1);
}

.my-bid-items {
    margin: 0 !important;
    padding: 4px !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}

.my-bid-items:hover {
    background-color: #00aed966;
}

.mybid-date-picker {
    flex: 1;
    display: flex;
}

.mybid-date-picker > div {
    flex: 1;
    display: inline-flex !important;
}

.mybid-date-picker > div > input {
    width: 0 !important;
    min-width: 100% !important;
}

div.popup .ui.celled.unstackable.center.aligned.table {
    min-width: 10em !important;
}

div.popup .ui.celled.unstackable.center.aligned.table th,
div.popup .ui.celled.unstackable.center.aligned.table td {
    padding: 3px 5px;
}

#carmon-logo {
    -webkit-animation: rotate-center 2.5s linear infinite both;
    animation: rotate-center 2.5s linear infinite both;
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes flip-vertical-right {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
}

@keyframes flip-vertical-right {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
}

