@font-face {
    font-family: 'Pretendard-Black';
    src: url('./fonts/Pretendard-Black.woff2') format('woff2');
    src: url('./fonts/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

.mobile-wrapper {
    min-height: 100vh;
    /* footer height + 20px */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #00aed9;
}

.mobile-wrapper * {
    font-family: 'Pretendard-Black', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.mobile-sub-title {
    font-size: 3.6em !important;
    color: #feb948 !important;
    padding: 0 !important;
    line-height: 1em !important;
}

.mobile-title {
    font-size: 5.6em !important;
    color: #feb948 !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 0.8em !important;
}

.mobile-header {
    font-size: 3em !important;
    padding: 0 !important;
}

.mobile-content {
    font-size: 1.6em !important;
    padding: 0 !important;
}